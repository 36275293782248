import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    businessName: '',
    industry: '',
    style: '',
    tone: '',
    images: [
     /* { id: 1, src: '../icons/BeautyFashion/barber1.svg', industry: 'BeautyFashion', style:'Modern', tone:'Minimalist'}, */   // sposób wczytania ikony w katalogu plus state i tone
     
      /* BEAUTY & FASHION id 1-99*/
      { id: 1, src: '../icons/BeautyFashion/BF1.svg', industry: 'BeautyFashion'},
      { id: 2, src: '../icons/BeautyFashion/BF2.svg', industry: 'BeautyFashion'},
      { id: 3, src: '../icons/BeautyFashion/BF3.svg', industry: 'BeautyFashion'},
      { id: 4, src: '../icons/BeautyFashion/BF4.svg', industry: 'BeautyFashion'},
      { id: 5, src: '../icons/BeautyFashion/BF5.svg', industry: 'BeautyFashion'},
      { id: 6, src: '../icons/BeautyFashion/BF6.svg', industry: 'BeautyFashion'},
      { id: 7, src: '../icons/BeautyFashion/BF7.svg', industry: 'BeautyFashion'},
      { id: 8, src: '../icons/BeautyFashion/BF8.svg', industry: 'BeautyFashion'},
      { id: 9, src: '../icons/BeautyFashion/BF9.svg', industry: 'BeautyFashion'},
      { id: 10, src: '../icons/BeautyFashion/BF10.svg', industry: 'BeautyFashion'},
      { id: 11, src: '../icons/BeautyFashion/BF11.svg', industry: 'BeautyFashion'},
      { id: 12, src: '../icons/BeautyFashion/BF12.svg', industry: 'BeautyFashion'},
      { id: 13, src: '../icons/BeautyFashion/BF13.svg', industry: 'BeautyFashion'},
      { id: 14, src: '../icons/BeautyFashion/BF14.svg', industry: 'BeautyFashion'},
      { id: 15, src: '../icons/BeautyFashion/BF15.svg', industry: 'BeautyFashion'},
      { id: 16, src: '../icons/BeautyFashion/BF16.svg', industry: 'BeautyFashion'},
      { id: 17, src: '../icons/BeautyFashion/BF17.svg', industry: 'BeautyFashion'},
      { id: 18, src: '../icons/BeautyFashion/BF18.svg', industry: 'BeautyFashion'},
      { id: 19, src: '../icons/BeautyFashion/BF19.svg', industry: 'BeautyFashion'},
      { id: 20, src: '../icons/BeautyFashion/BF20.svg', industry: 'BeautyFashion'},
      { id: 21, src: '../icons/BeautyFashion/BF21.svg', industry: 'BeautyFashion'},
      { id: 22, src: '../icons/BeautyFashion/BF22.svg', industry: 'BeautyFashion'},
      { id: 23, src: '../icons/BeautyFashion/BF23.svg', industry: 'BeautyFashion'},
      { id: 24, src: '../icons/BeautyFashion/BF24.svg', industry: 'BeautyFashion'},
      { id: 25, src: '../icons/BeautyFashion/BF25.svg', industry: 'BeautyFashion'},
      { id: 26, src: '../icons/BeautyFashion/BF26.svg', industry: 'BeautyFashion'},
      { id: 27, src: '../icons/BeautyFashion/BF27.svg', industry: 'BeautyFashion'},
      { id: 28, src: '../icons/BeautyFashion/BF28.svg', industry: 'BeautyFashion'},
      { id: 29, src: '../icons/BeautyFashion/BF29.svg', industry: 'BeautyFashion'},
      { id: 30, src: '../icons/BeautyFashion/BF30.svg', industry: 'BeautyFashion'},
      { id: 31, src: '../icons/BeautyFashion/BF31.svg', industry: 'BeautyFashion'},
      { id: 32, src: '../icons/BeautyFashion/BF32.svg', industry: 'BeautyFashion'},
      { id: 33, src: '../icons/BeautyFashion/BF33.svg', industry: 'BeautyFashion'},
      { id: 34, src: '../icons/BeautyFashion/BF34.svg', industry: 'BeautyFashion'},
      { id: 35, src: '../icons/BeautyFashion/BF35.svg', industry: 'BeautyFashion'},
      { id: 36, src: '../icons/BeautyFashion/BF36.svg', industry: 'BeautyFashion'},
      { id: 37, src: '../icons/BeautyFashion/BF37.svg', industry: 'BeautyFashion'},
      { id: 38, src: '../icons/BeautyFashion/BF38.svg', industry: 'BeautyFashion'},
      { id: 39, src: '../icons/BeautyFashion/BF39.svg', industry: 'BeautyFashion'},
      { id: 40, src: '../icons/BeautyFashion/BF40.svg', industry: 'BeautyFashion'},
      { id: 41, src: '../icons/BeautyFashion/BF41.svg', industry: 'BeautyFashion'},
      { id: 42, src: '../icons/BeautyFashion/BF42.svg', industry: 'BeautyFashion'},
      { id: 43, src: '../icons/BeautyFashion/BF43.svg', industry: 'BeautyFashion'},
      { id: 44, src: '../icons/BeautyFashion/BF44.svg', industry: 'BeautyFashion'},
      { id: 45, src: '../icons/BeautyFashion/BF45.svg', industry: 'BeautyFashion'},
      { id: 46, src: '../icons/BeautyFashion/BF46.svg', industry: 'BeautyFashion'},
      { id: 47, src: '../icons/BeautyFashion/BF47.svg', industry: 'BeautyFashion'},
      { id: 48, src: '../icons/BeautyFashion/BF48.svg', industry: 'BeautyFashion'},
      { id: 49, src: '../icons/BeautyFashion/BF49.svg', industry: 'BeautyFashion'},
      { id: 50, src: '../icons/BeautyFashion/BF50.svg', industry: 'BeautyFashion'},
      { id: 51, src: '../icons/BeautyFashion/BF51.svg', industry: 'BeautyFashion'},
      { id: 52, src: '../icons/BeautyFashion/BF52.svg', industry: 'BeautyFashion'},
      { id: 53, src: '../icons/BeautyFashion/BF53.svg', industry: 'BeautyFashion'},
      { id: 54, src: '../icons/BeautyFashion/BF54.svg', industry: 'BeautyFashion'},
      { id: 55, src: '../icons/BeautyFashion/BF55.svg', industry: 'BeautyFashion'},
      { id: 56, src: '../icons/BeautyFashion/BF56.svg', industry: 'BeautyFashion'},
      { id: 57, src: '../icons/BeautyFashion/BF57.svg', industry: 'BeautyFashion'},
      { id: 58, src: '../icons/BeautyFashion/BF58.svg', industry: 'BeautyFashion'},
      { id: 59, src: '../icons/BeautyFashion/BF59.svg', industry: 'BeautyFashion'},
      { id: 60, src: '../icons/BeautyFashion/BF60.svg', industry: 'BeautyFashion'},
      { id: 61, src: '../icons/BeautyFashion/BF61.svg', industry: 'BeautyFashion'},
      { id: 62, src: '../icons/BeautyFashion/BF62.svg', industry: 'BeautyFashion'},
      { id: 63, src: '../icons/BeautyFashion/BF63.svg', industry: 'BeautyFashion'},
      { id: 64, src: '../icons/BeautyFashion/BF64.svg', industry: 'BeautyFashion'},
      { id: 65, src: '../icons/BeautyFashion/BF65.svg', industry: 'BeautyFashion'},
      { id: 66, src: '../icons/BeautyFashion/BF66.svg', industry: 'BeautyFashion'},
      { id: 67, src: '../icons/BeautyFashion/BF67.svg', industry: 'BeautyFashion'},
      { id: 68, src: '../icons/BeautyFashion/BF68.svg', industry: 'BeautyFashion'},
      { id: 69, src: '../icons/BeautyFashion/BF69.svg', industry: 'BeautyFashion'},
      { id: 70, src: '../icons/BeautyFashion/BF70.svg', industry: 'BeautyFashion'},
     
      /* FOOD & RESTAURANT id 101-200*/
      { id: 101, src: '../icons/FoodRestaurant/FR1.svg', industry: 'FoodRestaurant'},  
      { id: 102, src: '../icons/FoodRestaurant/FR2.svg', industry: 'FoodRestaurant'}, 
      { id: 103, src: '../icons/FoodRestaurant/FR3.svg', industry: 'FoodRestaurant'}, 
      { id: 104, src: '../icons/FoodRestaurant/FR4.svg', industry: 'FoodRestaurant'}, 
      { id: 105, src: '../icons/FoodRestaurant/FR5.svg', industry: 'FoodRestaurant'}, 
      { id: 106, src: '../icons/FoodRestaurant/FR6.svg', industry: 'FoodRestaurant'}, 
      { id: 107, src: '../icons/FoodRestaurant/FR7.svg', industry: 'FoodRestaurant'}, 
      { id: 108, src: '../icons/FoodRestaurant/FR8.svg', industry: 'FoodRestaurant'}, 
      { id: 109, src: '../icons/FoodRestaurant/FR9.svg', industry: 'FoodRestaurant'}, 
      { id: 110, src: '../icons/FoodRestaurant/FR10.svg', industry: 'FoodRestaurant'}, 
      { id: 111, src: '../icons/FoodRestaurant/FR11.svg', industry: 'FoodRestaurant'},
      { id: 112, src: '../icons/FoodRestaurant/FR12.svg', industry: 'FoodRestaurant'},  
      { id: 113, src: '../icons/FoodRestaurant/FR13.svg', industry: 'FoodRestaurant'},  
      { id: 114, src: '../icons/FoodRestaurant/FR14.svg', industry: 'FoodRestaurant'},  
      { id: 115, src: '../icons/FoodRestaurant/FR15.svg', industry: 'FoodRestaurant'},  
      { id: 116, src: '../icons/FoodRestaurant/FR16.svg', industry: 'FoodRestaurant'},  
      { id: 117, src: '../icons/FoodRestaurant/FR17.svg', industry: 'FoodRestaurant'},  
      { id: 118, src: '../icons/FoodRestaurant/FR18.svg', industry: 'FoodRestaurant'},  
      { id: 119, src: '../icons/FoodRestaurant/FR19.svg', industry: 'FoodRestaurant'},  
      { id: 120, src: '../icons/FoodRestaurant/FR20.svg', industry: 'FoodRestaurant'},  
      { id: 121, src: '../icons/FoodRestaurant/FR21.svg', industry: 'FoodRestaurant'},  
      { id: 122, src: '../icons/FoodRestaurant/FR22.svg', industry: 'FoodRestaurant'},  
      { id: 123, src: '../icons/FoodRestaurant/FR23.svg', industry: 'FoodRestaurant'},  
      { id: 124, src: '../icons/FoodRestaurant/FR24.svg', industry: 'FoodRestaurant'},  
      { id: 125, src: '../icons/FoodRestaurant/FR25.svg', industry: 'FoodRestaurant'},  
      { id: 126, src: '../icons/FoodRestaurant/FR26.svg', industry: 'FoodRestaurant'},  
      { id: 127, src: '../icons/FoodRestaurant/FR27.svg', industry: 'FoodRestaurant'},  
      { id: 128, src: '../icons/FoodRestaurant/FR28.svg', industry: 'FoodRestaurant'},  
      { id: 129, src: '../icons/FoodRestaurant/FR29.svg', industry: 'FoodRestaurant'},  
      { id: 130, src: '../icons/FoodRestaurant/FR30.svg', industry: 'FoodRestaurant'},  
      { id: 131, src: '../icons/FoodRestaurant/FR31.svg', industry: 'FoodRestaurant'},  
      { id: 132, src: '../icons/FoodRestaurant/FR32.svg', industry: 'FoodRestaurant'},  
      { id: 133, src: '../icons/FoodRestaurant/FR33.svg', industry: 'FoodRestaurant'},  
      { id: 134, src: '../icons/FoodRestaurant/FR34.svg', industry: 'FoodRestaurant'},  
      { id: 135, src: '../icons/FoodRestaurant/FR35.svg', industry: 'FoodRestaurant'},  
      { id: 136, src: '../icons/FoodRestaurant/FR36.svg', industry: 'FoodRestaurant'},  
      { id: 137, src: '../icons/FoodRestaurant/FR37.svg', industry: 'FoodRestaurant'},  
      { id: 138, src: '../icons/FoodRestaurant/FR38.svg', industry: 'FoodRestaurant'},  
      { id: 139, src: '../icons/FoodRestaurant/FR39.svg', industry: 'FoodRestaurant'},  
      { id: 140, src: '../icons/FoodRestaurant/FR40.svg', industry: 'FoodRestaurant'},  
      { id: 141, src: '../icons/FoodRestaurant/FR41.svg', industry: 'FoodRestaurant'},  
      { id: 142, src: '../icons/FoodRestaurant/FR42.svg', industry: 'FoodRestaurant'},  
      { id: 143, src: '../icons/FoodRestaurant/FR43.svg', industry: 'FoodRestaurant'},  
      { id: 144, src: '../icons/FoodRestaurant/FR44.svg', industry: 'FoodRestaurant'},  
      { id: 145, src: '../icons/FoodRestaurant/FR45.svg', industry: 'FoodRestaurant'},  
      { id: 146, src: '../icons/FoodRestaurant/FR46.svg', industry: 'FoodRestaurant'},  
      { id: 147, src: '../icons/FoodRestaurant/FR47.svg', industry: 'FoodRestaurant'},  
      { id: 148, src: '../icons/FoodRestaurant/FR48.svg', industry: 'FoodRestaurant'},  
      { id: 149, src: '../icons/FoodRestaurant/FR49.svg', industry: 'FoodRestaurant'},  
      { id: 150, src: '../icons/FoodRestaurant/FR50.svg', industry: 'FoodRestaurant'},  
      { id: 151, src: '../icons/FoodRestaurant/FR51.svg', industry: 'FoodRestaurant'},  
      { id: 152, src: '../icons/FoodRestaurant/FR52.svg', industry: 'FoodRestaurant'},  
      { id: 153, src: '../icons/FoodRestaurant/FR53.svg', industry: 'FoodRestaurant'},  
      { id: 154, src: '../icons/FoodRestaurant/FR54.svg', industry: 'FoodRestaurant'},
      { id: 155, src: '../icons/FoodRestaurant/FR55.svg', industry: 'FoodRestaurant'},  
      { id: 156, src: '../icons/FoodRestaurant/FR56.svg', industry: 'FoodRestaurant'},
      { id: 157, src: '../icons/FoodRestaurant/FR57.svg', industry: 'FoodRestaurant'},
      { id: 158, src: '../icons/FoodRestaurant/FR58.svg', industry: 'FoodRestaurant'},
      { id: 159, src: '../icons/FoodRestaurant/FR59.svg', industry: 'FoodRestaurant'},
      { id: 160, src: '../icons/FoodRestaurant/FR60.svg', industry: 'FoodRestaurant'},
      
      /* HEALTH & FITNESS id 201-299 */
      { id: 201, src: '../icons/HealthFitness/HF1.svg', industry: 'HealthFitness'},
      { id: 202, src: '../icons/HealthFitness/HF2.svg', industry: 'HealthFitness'},
      { id: 203, src: '../icons/HealthFitness/HF3.svg', industry: 'HealthFitness'},
      { id: 204, src: '../icons/HealthFitness/HF4.svg', industry: 'HealthFitness'},
      { id: 205, src: '../icons/HealthFitness/HF5.svg', industry: 'HealthFitness'},
      { id: 206, src: '../icons/HealthFitness/HF6.svg', industry: 'HealthFitness'},
      { id: 207, src: '../icons/HealthFitness/HF7.svg', industry: 'HealthFitness'},
      { id: 208, src: '../icons/HealthFitness/HF8.svg', industry: 'HealthFitness'},
      { id: 209, src: '../icons/HealthFitness/HF9.svg', industry: 'HealthFitness'},
      { id: 210, src: '../icons/HealthFitness/HF10.svg', industry: 'HealthFitness'},
      { id: 211, src: '../icons/HealthFitness/HF11.svg', industry: 'HealthFitness'},
      { id: 212, src: '../icons/HealthFitness/HF12.svg', industry: 'HealthFitness'},
      { id: 213, src: '../icons/HealthFitness/HF13.svg', industry: 'HealthFitness'},
      { id: 214, src: '../icons/HealthFitness/HF14.svg', industry: 'HealthFitness'},
      { id: 215, src: '../icons/HealthFitness/HF15.svg', industry: 'HealthFitness'},
      { id: 216, src: '../icons/HealthFitness/HF16.svg', industry: 'HealthFitness'},
      { id: 217, src: '../icons/HealthFitness/HF17.svg', industry: 'HealthFitness'},
      { id: 218, src: '../icons/HealthFitness/HF18.svg', industry: 'HealthFitness'},
      { id: 219, src: '../icons/HealthFitness/HF19.svg', industry: 'HealthFitness'},
      { id: 220, src: '../icons/HealthFitness/HF20.svg', industry: 'HealthFitness'},
      { id: 221, src: '../icons/HealthFitness/HF21.svg', industry: 'HealthFitness'},
      { id: 222, src: '../icons/HealthFitness/HF22.svg', industry: 'HealthFitness'},
      { id: 223, src: '../icons/HealthFitness/HF23.svg', industry: 'HealthFitness'},
      { id: 224, src: '../icons/HealthFitness/HF24.svg', industry: 'HealthFitness'},
      { id: 225, src: '../icons/HealthFitness/HF25.svg', industry: 'HealthFitness'},
      { id: 226, src: '../icons/HealthFitness/HF26.svg', industry: 'HealthFitness'},
      { id: 227, src: '../icons/HealthFitness/HF27.svg', industry: 'HealthFitness'},
      { id: 228, src: '../icons/HealthFitness/HF28.svg', industry: 'HealthFitness'},
      { id: 229, src: '../icons/HealthFitness/HF29.svg', industry: 'HealthFitness'},
      { id: 230, src: '../icons/HealthFitness/HF30.svg', industry: 'HealthFitness'},
      { id: 231, src: '../icons/HealthFitness/HF31.svg', industry: 'HealthFitness'},
      { id: 232, src: '../icons/HealthFitness/HF32.svg', industry: 'HealthFitness'},
      { id: 233, src: '../icons/HealthFitness/HF33.svg', industry: 'HealthFitness'},
      { id: 234, src: '../icons/HealthFitness/HF34.svg', industry: 'HealthFitness'},
      { id: 235, src: '../icons/HealthFitness/HF35.svg', industry: 'HealthFitness'},
      { id: 236, src: '../icons/HealthFitness/HF36.svg', industry: 'HealthFitness'},
      { id: 237, src: '../icons/HealthFitness/HF37.svg', industry: 'HealthFitness'},
      { id: 238, src: '../icons/HealthFitness/HF38.svg', industry: 'HealthFitness'},
      { id: 239, src: '../icons/HealthFitness/HF39.svg', industry: 'HealthFitness'},
      { id: 240, src: '../icons/HealthFitness/HF40.svg', industry: 'HealthFitness'},
      { id: 241, src: '../icons/HealthFitness/HF41.svg', industry: 'HealthFitness'},
      { id: 242, src: '../icons/HealthFitness/HF42.svg', industry: 'HealthFitness'},
      { id: 243, src: '../icons/HealthFitness/HF43.svg', industry: 'HealthFitness'},
      { id: 244, src: '../icons/HealthFitness/HF44.svg', industry: 'HealthFitness'},
      { id: 245, src: '../icons/HealthFitness/HF45.svg', industry: 'HealthFitness'},
      { id: 246, src: '../icons/HealthFitness/HF46.svg', industry: 'HealthFitness'},
      { id: 247, src: '../icons/HealthFitness/HF47.svg', industry: 'HealthFitness'},
      { id: 248, src: '../icons/HealthFitness/HF48.svg', industry: 'HealthFitness'},
      { id: 249, src: '../icons/HealthFitness/HF49.svg', industry: 'HealthFitness'},
      { id: 250, src: '../icons/HealthFitness/HF50.svg', industry: 'HealthFitness'},
      { id: 251, src: '../icons/HealthFitness/HF51.svg', industry: 'HealthFitness'},
      { id: 252, src: '../icons/HealthFitness/HF52.svg', industry: 'HealthFitness'},
      { id: 253, src: '../icons/HealthFitness/HF53.svg', industry: 'HealthFitness'},
      { id: 254, src: '../icons/HealthFitness/HF54.svg', industry: 'HealthFitness'},
      { id: 255, src: '../icons/HealthFitness/HF55.svg', industry: 'HealthFitness'},
      
      /* MARKETING & SALES id 301-399 */
      { id: 301, src: '../icons/MarketingSales/MS1.svg', industry: 'MarketingSales'},
      { id: 302, src: '../icons/MarketingSales/MS2.svg', industry: 'MarketingSales'},
      { id: 303, src: '../icons/MarketingSales/MS3.svg', industry: 'MarketingSales'},
      { id: 304, src: '../icons/MarketingSales/MS4.svg', industry: 'MarketingSales'},
      { id: 305, src: '../icons/MarketingSales/MS5.svg', industry: 'MarketingSales'},
      { id: 306, src: '../icons/MarketingSales/MS6.svg', industry: 'MarketingSales'},
      { id: 307, src: '../icons/MarketingSales/MS7.svg', industry: 'MarketingSales'},
      { id: 308, src: '../icons/MarketingSales/MS8.svg', industry: 'MarketingSales'},
      { id: 309, src: '../icons/MarketingSales/MS9.svg', industry: 'MarketingSales'},
      { id: 310, src: '../icons/MarketingSales/MS10.svg', industry: 'MarketingSales'},
      { id: 311, src: '../icons/MarketingSales/MS11.svg', industry: 'MarketingSales'},
      { id: 312, src: '../icons/MarketingSales/MS12.svg', industry: 'MarketingSales'},
      { id: 313, src: '../icons/MarketingSales/MS13.svg', industry: 'MarketingSales'},
      { id: 314, src: '../icons/MarketingSales/MS14.svg', industry: 'MarketingSales'},
      { id: 315, src: '../icons/MarketingSales/MS15.svg', industry: 'MarketingSales'},
      { id: 316, src: '../icons/MarketingSales/MS16.svg', industry: 'MarketingSales'},
      { id: 317, src: '../icons/MarketingSales/MS17.svg', industry: 'MarketingSales'},
      { id: 318, src: '../icons/MarketingSales/MS18.svg', industry: 'MarketingSales'},
      { id: 319, src: '../icons/MarketingSales/MS19.svg', industry: 'MarketingSales'},
      { id: 320, src: '../icons/MarketingSales/MS20.svg', industry: 'MarketingSales'},
      { id: 321, src: '../icons/MarketingSales/MS21.svg', industry: 'MarketingSales'},
      { id: 322, src: '../icons/MarketingSales/MS22.svg', industry: 'MarketingSales'},
      { id: 323, src: '../icons/MarketingSales/MS23.svg', industry: 'MarketingSales'},
      { id: 324, src: '../icons/MarketingSales/MS24.svg', industry: 'MarketingSales'},
      { id: 325, src: '../icons/MarketingSales/MS25.svg', industry: 'MarketingSales'},
      { id: 326, src: '../icons/MarketingSales/MS26.svg', industry: 'MarketingSales'},
      { id: 327, src: '../icons/MarketingSales/MS27.svg', industry: 'MarketingSales'},
      { id: 328, src: '../icons/MarketingSales/MS28.svg', industry: 'MarketingSales'},
      { id: 329, src: '../icons/MarketingSales/MS29.svg', industry: 'MarketingSales'},
      { id: 330, src: '../icons/MarketingSales/MS30.svg', industry: 'MarketingSales'},
      { id: 331, src: '../icons/MarketingSales/MS31.svg', industry: 'MarketingSales'},
      { id: 332, src: '../icons/MarketingSales/MS32.svg', industry: 'MarketingSales'},
      { id: 333, src: '../icons/MarketingSales/MS33.svg', industry: 'MarketingSales'},
      { id: 334, src: '../icons/MarketingSales/MS34.svg', industry: 'MarketingSales'},
      { id: 335, src: '../icons/MarketingSales/MS35.svg', industry: 'MarketingSales'},
      { id: 336, src: '../icons/MarketingSales/MS36.svg', industry: 'MarketingSales'},
      { id: 337, src: '../icons/MarketingSales/MS37.svg', industry: 'MarketingSales'},
      { id: 338, src: '../icons/MarketingSales/MS38.svg', industry: 'MarketingSales'},
      { id: 339, src: '../icons/MarketingSales/MS39.svg', industry: 'MarketingSales'},
      { id: 340, src: '../icons/MarketingSales/MS40.svg', industry: 'MarketingSales'},
      { id: 341, src: '../icons/MarketingSales/MS41.svg', industry: 'MarketingSales'},
      { id: 342, src: '../icons/MarketingSales/MS42.svg', industry: 'MarketingSales'},
      { id: 343, src: '../icons/MarketingSales/MS43.svg', industry: 'MarketingSales'},
      { id: 344, src: '../icons/MarketingSales/MS44.svg', industry: 'MarketingSales'},
      { id: 345, src: '../icons/MarketingSales/MS45.svg', industry: 'MarketingSales'},
      { id: 346, src: '../icons/MarketingSales/MS46.svg', industry: 'MarketingSales'},
      { id: 347, src: '../icons/MarketingSales/MS47.svg', industry: 'MarketingSales'},

      /* REAL ESTATE & CONSTRUCTION id 401-499 */
      { id: 401, src: '../icons/RealEstateConstruction/RC1.svg', industry: 'RealEstateConstruction'},
      { id: 402, src: '../icons/RealEstateConstruction/RC2.svg', industry: 'RealEstateConstruction'},
      { id: 403, src: '../icons/RealEstateConstruction/RC3.svg', industry: 'RealEstateConstruction'},
      { id: 404, src: '../icons/RealEstateConstruction/RC4.svg', industry: 'RealEstateConstruction'},
      { id: 405, src: '../icons/RealEstateConstruction/RC5.svg', industry: 'RealEstateConstruction'},
      { id: 406, src: '../icons/RealEstateConstruction/RC6.svg', industry: 'RealEstateConstruction'},
      { id: 407, src: '../icons/RealEstateConstruction/RC7.svg', industry: 'RealEstateConstruction'},
      { id: 408, src: '../icons/RealEstateConstruction/RC8.svg', industry: 'RealEstateConstruction'},
      { id: 409, src: '../icons/RealEstateConstruction/RC9.svg', industry: 'RealEstateConstruction'},
      { id: 410, src: '../icons/RealEstateConstruction/RC10.svg', industry: 'RealEstateConstruction'},
      { id: 411, src: '../icons/RealEstateConstruction/RC11.svg', industry: 'RealEstateConstruction'},
      { id: 412, src: '../icons/RealEstateConstruction/RC12.svg', industry: 'RealEstateConstruction'},
      { id: 413, src: '../icons/RealEstateConstruction/RC13.svg', industry: 'RealEstateConstruction'},
      { id: 414, src: '../icons/RealEstateConstruction/RC14.svg', industry: 'RealEstateConstruction'},
      { id: 415, src: '../icons/RealEstateConstruction/RC15.svg', industry: 'RealEstateConstruction'},
      { id: 416, src: '../icons/RealEstateConstruction/RC16.svg', industry: 'RealEstateConstruction'},
      { id: 417, src: '../icons/RealEstateConstruction/RC17.svg', industry: 'RealEstateConstruction'},
      { id: 418, src: '../icons/RealEstateConstruction/RC18.svg', industry: 'RealEstateConstruction'},
      { id: 419, src: '../icons/RealEstateConstruction/RC19.svg', industry: 'RealEstateConstruction'},
      { id: 420, src: '../icons/RealEstateConstruction/RC20.svg', industry: 'RealEstateConstruction'},
      { id: 421, src: '../icons/RealEstateConstruction/RC21.svg', industry: 'RealEstateConstruction'},
      { id: 422, src: '../icons/RealEstateConstruction/RC22.svg', industry: 'RealEstateConstruction'},
      { id: 423, src: '../icons/RealEstateConstruction/RC23.svg', industry: 'RealEstateConstruction'},
      { id: 424, src: '../icons/RealEstateConstruction/RC24.svg', industry: 'RealEstateConstruction'},
      { id: 425, src: '../icons/RealEstateConstruction/RC25.svg', industry: 'RealEstateConstruction'},
      { id: 426, src: '../icons/RealEstateConstruction/RC26.svg', industry: 'RealEstateConstruction'},
      { id: 427, src: '../icons/RealEstateConstruction/RC27.svg', industry: 'RealEstateConstruction'},
      { id: 428, src: '../icons/RealEstateConstruction/RC28.svg', industry: 'RealEstateConstruction'},
      { id: 429, src: '../icons/RealEstateConstruction/RC29.svg', industry: 'RealEstateConstruction'},
      { id: 430, src: '../icons/RealEstateConstruction/RC30.svg', industry: 'RealEstateConstruction'},
      { id: 431, src: '../icons/RealEstateConstruction/RC31.svg', industry: 'RealEstateConstruction'},
      { id: 432, src: '../icons/RealEstateConstruction/RC32.svg', industry: 'RealEstateConstruction'},
      { id: 433, src: '../icons/RealEstateConstruction/RC33.svg', industry: 'RealEstateConstruction'},
      { id: 434, src: '../icons/RealEstateConstruction/RC34.svg', industry: 'RealEstateConstruction'},
      { id: 434, src: '../icons/RealEstateConstruction/RC35.svg', industry: 'RealEstateConstruction'},

      /* FINANCE & LEGAL id 501-599 */
      { id: 501, src: '../icons/FinanceLegal/FL1.svg', industry: 'FinanceLegal'},
      { id: 502, src: '../icons/FinanceLegal/FL2.svg', industry: 'FinanceLegal'},
      { id: 503, src: '../icons/FinanceLegal/FL3.svg', industry: 'FinanceLegal'},
      { id: 504, src: '../icons/FinanceLegal/FL4.svg', industry: 'FinanceLegal'},
      { id: 505, src: '../icons/FinanceLegal/FL5.svg', industry: 'FinanceLegal'},
      { id: 506, src: '../icons/FinanceLegal/FL6.svg', industry: 'FinanceLegal'},
      { id: 507, src: '../icons/FinanceLegal/FL7.svg', industry: 'FinanceLegal'},
      { id: 508, src: '../icons/FinanceLegal/FL8.svg', industry: 'FinanceLegal'},
      { id: 509, src: '../icons/FinanceLegal/FL9.svg', industry: 'FinanceLegal'},
      { id: 510, src: '../icons/FinanceLegal/FL10.svg', industry: 'FinanceLegal'},
      { id: 511, src: '../icons/FinanceLegal/FL11.svg', industry: 'FinanceLegal'},
      { id: 512, src: '../icons/FinanceLegal/FL12.svg', industry: 'FinanceLegal'},
      { id: 513, src: '../icons/FinanceLegal/FL13.svg', industry: 'FinanceLegal'},
      { id: 514, src: '../icons/FinanceLegal/FL14.svg', industry: 'FinanceLegal'},
      { id: 515, src: '../icons/FinanceLegal/FL15.svg', industry: 'FinanceLegal'},
      { id: 516, src: '../icons/FinanceLegal/FL16.svg', industry: 'FinanceLegal'},
      { id: 517, src: '../icons/FinanceLegal/FL17.svg', industry: 'FinanceLegal'},
      { id: 518, src: '../icons/FinanceLegal/FL18.svg', industry: 'FinanceLegal'},
      { id: 519, src: '../icons/FinanceLegal/FL19.svg', industry: 'FinanceLegal'},
      { id: 520, src: '../icons/FinanceLegal/FL20.svg', industry: 'FinanceLegal'},
      { id: 521, src: '../icons/FinanceLegal/FL21.svg', industry: 'FinanceLegal'},
      { id: 522, src: '../icons/FinanceLegal/FL22.svg', industry: 'FinanceLegal'},
      { id: 523, src: '../icons/FinanceLegal/FL23.svg', industry: 'FinanceLegal'},
      { id: 524, src: '../icons/FinanceLegal/FL24.svg', industry: 'FinanceLegal'},
      { id: 525, src: '../icons/FinanceLegal/FL25.svg', industry: 'FinanceLegal'},
      { id: 526, src: '../icons/FinanceLegal/FL26.svg', industry: 'FinanceLegal'},
      { id: 527, src: '../icons/FinanceLegal/FL27.svg', industry: 'FinanceLegal'},
      { id: 528, src: '../icons/FinanceLegal/FL28.svg', industry: 'FinanceLegal'},
      { id: 529, src: '../icons/FinanceLegal/FL29.svg', industry: 'FinanceLegal'},
      { id: 530, src: '../icons/FinanceLegal/FL30.svg', industry: 'FinanceLegal'},
      { id: 531, src: '../icons/FinanceLegal/FL31.svg', industry: 'FinanceLegal'},
      { id: 532, src: '../icons/FinanceLegal/FL32.svg', industry: 'FinanceLegal'},
      { id: 533, src: '../icons/FinanceLegal/FL33.svg', industry: 'FinanceLegal'},
      { id: 534, src: '../icons/FinanceLegal/FL34.svg', industry: 'FinanceLegal'},
      { id: 535, src: '../icons/FinanceLegal/FL35.svg', industry: 'FinanceLegal'},
      
      /* OTHER id 1001 */
      { id: 1001, src: '../icons/Other/O1.svg', industry: 'Other'},
      { id: 1002, src: '../icons/Other/O2.svg', industry: 'Other'},
      { id: 1003, src: '../icons/Other/O3.svg', industry: 'Other'},
      { id: 1004, src: '../icons/Other/O4.svg', industry: 'Other'}, 
      { id: 1005, src: '../icons/Other/O5.svg', industry: 'Other'},
      { id: 1006, src: '../icons/Other/O6.svg', industry: 'Other'},
      { id: 1007, src: '../icons/Other/O7.svg', industry: 'Other'},
      { id: 1008, src: '../icons/Other/O8.svg', industry: 'Other'},
      { id: 1009, src: '../icons/Other/O9.svg', industry: 'Other'},
      { id: 1010, src: '../icons/Other/O10.svg', industry: 'Other'},
      { id: 1011, src: '../icons/Other/O11.svg', industry: 'Other'},    
      { id: 1012, src: '../icons/Other/O12.svg', industry: 'Other'},      
      { id: 1013, src: '../icons/Other/O13.svg', industry: 'Other'}, 
      { id: 1014, src: '../icons/Other/O14.svg', industry: 'Other'},
      { id: 1015, src: '../icons/Other/O15.svg', industry: 'Other'},
      { id: 1016, src: '../icons/Other/O16.svg', industry: 'Other'}, 
      { id: 1017, src: '../icons/Other/O17.svg', industry: 'Other'},
      { id: 1018, src: '../icons/Other/O18.svg', industry: 'Other'},
      { id: 1019, src: '../icons/Other/O19.svg', industry: 'Other'},
      { id: 1020, src: '../icons/Other/O20.svg', industry: 'Other'},
      { id: 1021, src: '../icons/Other/O21.svg', industry: 'Other'},
      { id: 1022, src: '../icons/Other/O22.svg', industry: 'Other'},
      { id: 1023, src: '../icons/Other/O23.svg', industry: 'Other'},
      { id: 1024, src: '../icons/Other/O24.svg', industry: 'Other'},
      { id: 1025, src: '../icons/Other/O25.svg', industry: 'Other'},
      { id: 1026, src: '../icons/Other/O26.svg', industry: 'Other'},
      { id: 1027, src: '../icons/Other/O27.svg', industry: 'Other'},
      { id: 1028, src: '../icons/Other/O28.svg', industry: 'Other'},
      { id: 1029, src: '../icons/Other/O29.svg', industry: 'Other'},
      { id: 1030, src: '../icons/Other/O30.svg', industry: 'Other'},
      { id: 1031, src: '../icons/Other/O31.svg', industry: 'Other'},
      { id: 1032, src: '../icons/Other/O32.svg', industry: 'Other'},
      { id: 1033, src: '../icons/Other/O33.svg', industry: 'Other'},
      { id: 1034, src: '../icons/Other/O34.svg', industry: 'Other'},
      { id: 1035, src: '../icons/Other/O35.svg', industry: 'Other'},
      { id: 1036, src: '../icons/Other/O36.svg', industry: 'Other'},
      { id: 1037, src: '../icons/Other/O37.svg', industry: 'Other'},
      { id: 1038, src: '../icons/Other/O38.svg', industry: 'Other'},
      { id: 1039, src: '../icons/Other/O39.svg', industry: 'Other'},
      { id: 1040, src: '../icons/Other/O40.svg', industry: 'Other'},
      { id: 1041, src: '../icons/Other/O41.svg', industry: 'Other'},
      { id: 1042, src: '../icons/Other/O42.svg', industry: 'Other'},
      { id: 1043, src: '../icons/Other/O43.svg', industry: 'Other'},
      { id: 1044, src: '../icons/Other/O44.svg', industry: 'Other'},
      { id: 1045, src: '../icons/Other/O45.svg', industry: 'Other'},
      { id: 1046, src: '../icons/Other/O46.svg', industry: 'Other'},
      { id: 1047, src: '../icons/Other/O47.svg', industry: 'Other'},
      { id: 1048, src: '../icons/Other/O48.svg', industry: 'Other'},
      { id: 1049, src: '../icons/Other/O49.svg', industry: 'Other'},
      { id: 1050, src: '../icons/Other/O50.svg', industry: 'Other'},
      { id: 1051, src: '../icons/Other/O51.svg', industry: 'Other'},
      { id: 1052, src: '../icons/Other/O52.svg', industry: 'Other'},
      { id: 1053, src: '../icons/Other/O53.svg', industry: 'Other'},
      { id: 1054, src: '../icons/Other/O54.svg', industry: 'Other'},
      { id: 1055, src: '../icons/Other/O55.svg', industry: 'Other'},
      { id: 1056, src: '../icons/Other/O56.svg', industry: 'Other'},
      { id: 1057, src: '../icons/Other/O57.svg', industry: 'Other'},
      { id: 1058, src: '../icons/Other/O58.svg', industry: 'Other'},
      { id: 1059, src: '../icons/Other/O59.svg', industry: 'Other'},
      { id: 1060, src: '../icons/Other/O60.svg', industry: 'Other'}, 
      { id: 1061, src: '../icons/Other/O61.svg', industry: 'Other'},  
      { id: 1062, src: '../icons/Other/O62.svg', industry: 'Other'}, 
      { id: 1063, src: '../icons/Other/O63.svg', industry: 'Other'}, 
      { id: 1064, src: '../icons/Other/O64.svg', industry: 'Other'}, 
      { id: 1065, src: '../icons/Other/O65.svg', industry: 'Other'}, 
      { id: 1066, src: '../icons/Other/O66.svg', industry: 'Other'}, 
      { id: 1067, src: '../icons/Other/O67.svg', industry: 'Other'}, 
      { id: 1068, src: '../icons/Other/O68.svg', industry: 'Other'}, 
      { id: 1069, src: '../icons/Other/O69.svg', industry: 'Other'}, 
      { id: 1070, src: '../icons/Other/O70.svg', industry: 'Other'}, 
      { id: 1071, src: '../icons/Other/O71.svg', industry: 'Other'}, 
      { id: 1072, src: '../icons/Other/O72.svg', industry: 'Other'},
      { id: 1073, src: '../icons/Other/O73.svg', industry: 'Other'},
      { id: 1074, src: '../icons/Other/O74.svg', industry: 'Other'},
      { id: 1075, src: '../icons/Other/O75.svg', industry: 'Other'},
      { id: 1076, src: '../icons/Other/O76.svg', industry: 'Other'},
      { id: 1077, src: '../icons/Other/O77.svg', industry: 'Other'},
      { id: 1078, src: '../icons/Other/O78.svg', industry: 'Other'},
      { id: 1079, src: '../icons/Other/O79.svg', industry: 'Other'},
      { id: 1080, src: '../icons/Other/O80.svg', industry: 'Other'},
      { id: 1081, src: '../icons/Other/O81.svg', industry: 'Other'},
      { id: 1082, src: '../icons/Other/O82.svg', industry: 'Other'},
      { id: 1083, src: '../icons/Other/O83.svg', industry: 'Other'},
      { id: 1084, src: '../icons/Other/O84.svg', industry: 'Other'},
      { id: 1085, src: '../icons/Other/O85.svg', industry: 'Other'},
      { id: 1086, src: '../icons/Other/O86.svg', industry: 'Other'},
      { id: 1087, src: '../icons/Other/O87.svg', industry: 'Other'},
      { id: 1088, src: '../icons/Other/O88.svg', industry: 'Other'},
      { id: 1089, src: '../icons/Other/O89.svg', industry: 'Other'},
      { id: 1090, src: '../icons/Other/O90.svg', industry: 'Other'},

    ],
    selectedImage: null
  },
  mutations: {
    setBusinessName(state, name) {
      state.businessName = name
    },
    setIndustry(state, industry) {
      state.industry = industry
    },
    setStyle(state, style) {
      state.style = style
    },
    setTone(state, tone) {
      state.tone = tone
    },
    setSelectedImage(state, image) {
      state.selectedImage = image
    }
  },
  getters: {
     filteredImages: (state) => {
      return state.images.filter((image) => image.industry === state.industry);
    },
    /*
    dla kilku opcji filtoranie np. obrazy, kolory i styl 
    filteredImages: state => {
      let filtered = state.images.filter(image => {
        return image.industry === state.industry
      })
    
      if (state.style) {
        filtered = filtered.filter(image => {
          return image.style === state.style || image.tone === state.tone
        })
      }
    
      return filtered
    }, */
    getImageById: state => src => {
      return state.images.find(image => image.src === src)
    }
  }
});

export default store;
