<template>
  <div class="bg-green-900">
    <div
        v-if="step === 1"
        class="w-screen h-screen flex flex-col justify-between items-center"
      >
        <!-- LOGO BAR -->
        <div
          class="w-full h-[50px] bg-white flex justify-between items-center text-black"
        >
        <router-link class="ml-[5%]" to="/">
        <p class="font-inter font-bold text-2xl">
          Business<span class="text-secondary">Page</span>
        </p>
        </router-link>
          <h2 class="w-fit font-inter text-base text-primary">{{ step }}/4</h2>
        </div>
  
        <!-- MAIN CARD 1 -->
        <div
          class="animate-fade-up animate-ease-in-out h-4/5 xl:h-2/3 max-w-[90%] lg:max-w-[80%] w-full my-0 mx-auto font-inter"
        >
          <div
            class="h-full w-full max-w-[90%] lg:max-w-[80%] my-0 mx-auto px-3 pt-2 pb-6 overflow-auto flex flex-col justify-start items-center rounded-md"
          >
            <div class="w-full">
              <h1 class="py-2 font-bold text-3xl lg:text-4xl xl:text-5xl">
                Q1. What is the name of your business?
              </h1>
              <h2 class="mt-4 text-xl lg:text-2xl">
                We'd love to know your unique business name.
              </h2>
            </div>
            <div class="w-full mt-16 flex justify-center items-center">
              <div
                class="w-11/12 md:w-2/5 flex flex-col justify-center items-center"
              >
                <input
                  type="text"
                  placeholder="Enter your business name"
                  class="rounded-md border-2 font-bold w-full border-neutral-300 py-2 px-4 text-primary"
                  v-model="businessName"
                  maxlength="15"
                />
                <p
                  class="p-1 mt-2 self-start text-xs lg:text-sm text-neutralGray"
                >
                  Business name should not be longer than 15 characters
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <!-- FOOTER -->
        <div class="w-full h-[50px] bg-white flex justify-between items-center">
          <div class="w-full px-4 flex gap-4 justify-center">
            <button
              class="invisible w-[109px] py-1 bg-white border border-primary text-primary disabled:bg-neutral-400 hover:bg-neutral-300 rounded-lg text-base font-inter font-bold"
              @click="previousStep()"
              :disabled="!businessName"
            >
              Previous
            </button>
  
            <button
              class="w-[109px] py-1 text-center bg-white border border-primary text-primary disabled:bg-neutral-300 hover:bg-neutral-300 rounded-lg text-base font-inter font-bold"
              @click="nextStep()"
              :disabled="!businessName"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <div
          v-else-if="step === 2"
          class="w-screen h-screen flex flex-col justify-between items-center"
        >
        <!-- LOGO BAR -->
        <div
          class="w-full h-[50px] bg-white flex justify-between items-center text-black"
        >
        <router-link class="ml-[5%]" to="/">
        <p class="font-inter font-bold text-2xl">
          Business<span class="text-secondary">Page</span>
        </p>
        </router-link>
          <h2 class="w-fit font-inter text-base text-primary">{{ step }}/4</h2>
          <!-- THIS IS NOT VISIBLE, ONLY FOR CENTERING PURPOSES -->
          <p class="mr-[5%] font-inter font-bold text-2xl invisible">
            Logo<span class="text-secondary">Ya</span>
          </p>
        </div>

        <!-- MAIN CARD 2 -->
        <div
          class="h-4/5 xl:h-2/3 max-w-[90%] lg:max-w-[80%] w-full my-0 mx-auto font-inter"
        >
        <div
          class="h-full w-full max-w-[90%] lg:max-w-[80%] my-0 mx-auto px-3 pt-2 pb-6 overflow-auto flex flex-col justify-start items-center rounded-md"
        >
          <div class="pb-4 w-full">
            <h1 class="py-2 font-bold text-3xl lg:text-4xl xl:text-5xl">
              Q2. What industry are you in?
            </h1>
            <h2 class="mt-4 text-xl lg:text-2xl">
              Help us understand your specific target market or customer
              segment.
            </h2>
          </div>
          <div
            class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 auto-rows-fr"
          >
            <button
              class="btn"
              @click="selectIndustry('BeautyFashion')"
              :class="{ selected: industry === 'BeautyFashion' }"
            >
              Beauty & Fashion
            </button>
            <button
              class="btn"
              @click="selectIndustry('FoodRestaurant')"
              :class="{ selected: industry === 'FoodRestaurant' }"
            >
              Food & Restaurant
            </button>
            <button
              class="btn"
              @click="selectIndustry('HealthFitness')"
              :class="{ selected: industry === 'HealthFitness' }"
            >
              Health & Fitness
            </button>
            <button
              class="btn"
              @click="selectIndustry('MarketingSales')"
              :class="{ selected: industry === 'MarketingSales' }"
            >
              Marketing & Sales
            </button>
            <button
              class="btn"
              @click="selectIndustry('RealEstateConstruction')"
              :class="{ selected: industry === 'RealEstateConstruction' }"
            >
              Real Estate & Construction
            </button>
            <button
              class="btn"
              @click="selectIndustry('FinanceLegal')"
              :class="{ selected: industry === 'FinanceLegal' }"
            >
              Finance & Legal
            </button>
            <button
              class="btn"
              @click="selectIndustry('Industry7')"
              :class="{ selected: industry === 'Industry7' }"
            >
              Industry7
            </button>
            <button
              class="btn"
              @click="selectIndustry('Industry8')"
              :class="{ selected: industry === 'Industry8' }"
            >
            Industry8
            </button>
            <button
              class="btn"
              @click="selectIndustry('Industry9')"
              :class="{ selected: industry === 'Industry9' }"
            >
            Industry9
            </button>
            <button
              class="btn"
              @click="selectIndustry('Industry10')"
              :class="{ selected: industry === 'Industry10' }"
            >
            Industry10
            </button>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="w-full h-[50px] bg-white flex justify-between items-center">
        <div class="w-full px-4 flex gap-4 justify-center">

          <button
            class="w-[109px] py-1 bg-white border border-primary text-primary disabled:bg-neutral-400 hover:bg-neutral-300 rounded-lg text-base font-inter font-bold"
            @click="previousStep()"
            :disabled="!businessName"
          >
            Previous
          </button>

          <button
            class="w-[109px] py-1 text-center bg-white border border-primary text-primary disabled:bg-neutral-300 hover:bg-neutral-300 rounded-lg text-base font-inter font-bold"
            @click="nextStep()"
            :disabled="!industry"
          >
            Next
          </button>
        </div>
      </div>
    </div>

    <div
        v-if="step === 3"
        class="w-screen h-screen flex flex-col justify-between items-center"
      >
      <!-- LOGO BAR -->
      <div
        class="w-full h-[50px] bg-white flex justify-between items-center text-black"
      >
      <router-link class="ml-[5%]" to="/">
      <p class="font-inter font-bold text-2xl">
        Business<span class="text-secondary">Page</span>
      </p>
      </router-link>
        <h2 class="w-fit font-inter text-base text-primary">{{ step }}/4</h2>
        <!-- THIS IS NOT VISIBLE, ONLY FOR CENTERING PURPOSES -->
        <p class="mr-[5%] font-inter font-bold text-2xl invisible">
          Business<span class="text-secondary">Page</span>
        </p>
      </div>

      <!-- MAIN CARD 3 -->
      <div
        class="h-4/5 xl:h-2/3 max-w-[90%] lg:max-w-[80%] w-full my-0 mx-auto font-inter"
      >
        <div
          class="h-full w-full max-w-[90%] lg:max-w-[80%] my-0 mx-auto px-3 pt-2 pb-6 overflow-auto flex flex-col justify-start items-center rounded-md"
        >
          <div class="pb-4 w-full">
            <h1 class="py-2 font-bold text-3xl lg:text-4xl xl:text-5xl">
              Q3. Business Description
            </h1>
            <h2 class="mt-4 text-xl lg:text-2xl">
              My Business Helps ...
            </h2>
          </div>
          <div class="w-full mt-16 flex justify-center items-center">
            <div
              class="w-11/12 md:w-2/5 flex flex-col justify-center items-center"
            >
              <input
                type="text"
                placeholder="Enter your text"
                class="rounded-md border-2 font-bold w-full border-neutral-300 py-2 px-4 text-primary"
                v-model="description"
              />              
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="w-full h-[50px] bg-white flex justify-between items-center">
        <div class="w-full px-4 flex gap-4 justify-center">

          <button
            class="w-[109px] py-1 bg-white border border-primary text-primary disabled:bg-neutral-400 hover:bg-neutral-300 rounded-lg text-base font-inter font-bold"
            @click="previousStep()"
            :disabled="!businessName"
          >
            Previous
          </button>

          <button
            class="w-[109px] py-1 text-center bg-white border border-primary text-primary disabled:bg-neutral-300 hover:bg-neutral-300 rounded-lg text-base font-inter font-bold"
            @click="nextStep()"
            :disabled="!description"
          >
            Next
          </button>
        </div>
      </div>
    </div>

    <div
        v-if="step === 4"
        class="w-screen h-screen flex flex-col justify-between items-center"
      >
      <!-- LOGO BAR -->
      <div
        class="w-full h-[50px] bg-white flex justify-between items-center text-black"
      >
      <router-link class="ml-[5%]" to="/">
      <p class="font-inter font-bold text-2xl">
        Business<span class="text-secondary">Page</span>
      </p>
      </router-link>
        <h2 class="w-fit font-inter text-base text-primary">{{ step }}/4</h2>
        <!-- THIS IS NOT VISIBLE, ONLY FOR CENTERING PURPOSES -->
        <p class="mr-[5%] font-inter font-bold text-2xl invisible">
          Business<span class="text-secondary">Page</span>
        </p>
      </div>

      <!-- MAIN CARD 4 -->
      <div
        class="h-4/5 xl:h-2/3 max-w-[90%] lg:max-w-[80%] w-full my-0 mx-auto text-white font-inter"
      >
        <div
          class="h-full w-full max-w-[90%] lg:max-w-[80%] my-0 mx-auto px-3 pt-2 pb-6 overflow-auto flex flex-col justify-start items-center rounded-md"
        >
          <div class="pb-4 w-full">
            <h1 class="py-2 font-bold text-3xl lg:text-4xl xl:text-5xl">
              Q4.
            </h1>
            <h2 class="mt-4 text-xl lg:text-2xl">
              question 4
            </h2>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="w-full h-[50px] bg-white flex justify-between items-center">
        <div class="w-full px-4 flex gap-4 justify-center">

          <button
            class="w-[109px] py-1 bg-white border border-primary text-primary disabled:bg-neutral-400 hover:bg-neutral-300 rounded-lg text-base font-inter font-bold"
            @click="previousStep()"
            :disabled="!businessName"
          >
            Previous
          </button>

          <button
            class="w-[109px] py-1 text-center bg-white border border-primary text-primary disabled:bg-neutral-300 hover:bg-neutral-300 rounded-lg text-base font-inter font-bold"
            @click="nextStep()"
            :disabled="!style"
          >
            Next
          </button>
        </div>
      </div>
    </div>

    </div> 
  </template>
  
  <script>
  export default {
    data() {
      return {
        businessName: "",
        industry: "",
        description: "",
        step: 1,
      };
    },
    methods: {
      nextStep() {
        switch (this.step) {
          case 1:
            this.$store.commit("setBusinessName", this.businessName);
            this.step++;
            break;
          case 2:
            this.$store.commit("setIndustry", this.industry);
            this.step++;
            break;
          case 3:
            this.$store.commit("setDescription", this.description);
            this.step++;
            break;
          case 4:
            this.$store.commit("setQuestion4", this.question4);
            this.step++;
            break;
          case 5:
            this.$store.commit("setQuestion5", this.question5);
            this.step++;
            break;
          case 6:
            this.$store.commit("setQuestion6", this.question6);
            this.step++;
            break;
          case 7:
            this.$store.commit("setQuestion7", this.question7);
            this.step++;
            break;
          case 8:
            this.$store.commit("setQuestion8", this.question8);
            this.step++;
            break;
        }
      },
      previousStep() {
        this.step--;
      },
      selectIndustry(industry) {
        this.industry = industry;
      },
      selectDescription(description) {
        this.description = description;
      },
      selectQuestion4(question4) {
        this.question4 = question4;
      },
      selectQuestion5(question5) {
        this.question5 = question5;
      },
      selectQuestion6(question6) {
        this.question6 = question6;
      },
      selectQuestion7(question7) {
        this.question7 = question7;
      },
      selectQuestion8(question8) {
        this.question8 = question8;
      },
    },
  };
  </script>
  <style scoped>
  .btn {
    @apply m-3 py-4 px-8 text-center bg-white text-gray-400 font-bold font-inter rounded-md shadow; /* Default style for buttons */
  }
  .selected {
    @apply bg-secondary text-white; /* Add a style for selected buttons */
  }
  </style>
  