import Vue from "vue";
import VueRouter from "vue-router";
import LogoQuestionnaire from "../views/LogoQuestionnaire.vue";
import LogoProposals from "../views/LogoProposals.vue";
import LogoModify from "../views/LogoModify.vue";
import LogoPreview from "../views/LogoPreview.vue";
import LogoBuy from "../views/LogoBuy.vue";
import LogoYaLandingPage from "../views/LogoYaLandingPage";
import SignUp from "../views/SignUp";
import testCanva from "../views/testCanva"
import BusinessLandingPage from "../views/BusinessLandingPage"
import SignIn from "../views/SignIn";
import AllTools from "@/views/AllTools.vue";
import UserDashboard from "@/views/UserDashboard/UserDashboard.vue";
import Dashboard1 from "@/views/UserDashboard/DashboardPage.vue";
import Dashboard2 from "@/views/UserDashboard/DashboardPage2.vue";

// LEAD MAGNET LANDING PAGE
import LeadMagnetBeauty from "@/views/LeadMagnetBeauty.vue";
import LeadMagnetGym from "@/views/LeadMagnetGym.vue";
import LeadMagnetRealEstate from "@/views/LeadMagnetRealEstate.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: LogoYaLandingPage,
    meta: {
      title: "All-in-one branding solution | Build and grow your brand with LogoYa",
    },
  },
  {
    path: "/questionnaire",
    name: "questionnaire",
    component: LogoQuestionnaire,
    meta: {
      title: "Build your brand with simple LogoYa brand builder",
    },    
  },
  {
    path: "/proposals",
    name: "proposals",
    component: LogoProposals,
    meta: {
      title: "Build your brand with simple LogoYa brand builder",
    },  
  },
  {
    path: "/modify",
    name: "modify",
    component: LogoModify,
    meta: {
      title: "Build your brand with simple LogoYa brand builder",
    },  
  },
  {
    path: "/preview",
    name: "preview",
    component: LogoPreview,
  },
  {
    path: "/buy",
    name: "buy",
    component: LogoBuy,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
  },
  {
    path: "/testCanva",
    name: "testCanva",
    component: testCanva,
  },
  {
    path: "/business",
    name: "business",
    component: BusinessLandingPage,
  },
  {
    path: "/signin",
    name: "signin",
    component: SignIn,
  },
  {
    path: "/all-tools",
    name: "allTools",
    component: AllTools,    
    meta: {
      title: "Build and grow your business with these tools | LogoYa",
    },  
  },
  {
    path: "/lmbeauty",
    name: "leadMagnetBeauty",
    component: LeadMagnetBeauty,
    meta: {
      title: "All-in-one branding solution | Build and grow your brand with LogoYa",
    },
  },
  {
    path: "/lmgym",
    name: "leadMagnetGym",
    component: LeadMagnetGym,
    meta: {
      title: "All-in-one branding solution | Build and grow your brand with LogoYa",
    },
  },
  {
    path: "/lmrealestate",
    name: "leadMagnetRealEstate",
    component: LeadMagnetRealEstate,
    meta: {
      title: "All-in-one branding solution | Build and grow your brand with LogoYa",
    },
  },
  {
    path: '/userDashboard',
    name: "userDashboard",
    component: UserDashboard,
    meta: {
      title: "User Dashboard | LogoYa",
    }, 
    children : [
      {
        path: '/dashboardPage',
        name: "dashboardPage",
        component: Dashboard1,
        meta: {
          title: "User Dashboard | LogoYa",
        },
      },
      {
        path: '/dashboardPage2',
        name: "dashboardPage2",
        component: Dashboard2,
        meta: {
          title: "User Dashboard | LogoYa",
        },
      } 
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
})

export default router;
