<template lang="">
<!-- HERO SECTION -->
<div class="bg-white text-primary h-[100vh] relative flex items-center justify-center p-8">
  <div class="max-w-screen mx-auto animate-ease-in-out">
    <!-- Single Column -->
    <div class="max-w-lg mx-auto text-center">
      <h1 class="animate-fade-right animate-delay-300 text-4xl font-bold tracking-tight text-gray-900 pt-4 sm:pt-0">3 Marketing Strategies to Success</h1>
      <p class="animate-fade-left animate-delay-300 mt-4 text-base sm:text-lg leading-6 text-gray-600">Ready to take your business to new heights? Discover powerful marketing strategies tailored to your industry and designed to drive growth. Sign up now to receive your free Gym Marketing Strategies Checklist and start attracting more customers today!</p>
      <div class="mt-8">
        <div class="ml-embedded" data-form="VVE6y2"></div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  methods: {
    allTools() {
      this.$router.push("/all-tools");
    },
  },
};
</script>
<!-- MailerLite Universal -->
<script>
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '193462');
</script>
<!-- End MailerLite Universal -->
<style lang=""></style>