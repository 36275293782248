<template>
  <div class="h-screen flex flex-col justify-between items-center">
    <!-- LOGO BAR -->
    <div
      class="w-full h-[50px] bg-white flex justify-start items-center text-black"
    >
    <router-link class="ml-[5%]" to="/">
      <p class="font-inter font-bold text-2xl">
        Logo<span class="text-secondary">Ya</span>
      </p>
      </router-link>
    </div>

    <!-- MAIN PROPOSALS -->
    <div
      class="animate-fade-up animate-ease-in-out h-4/5 mx-auto px-5 sm:px-10 lg:px-12 xl:px-20 flex flex-col items-center text-primary"
    >
      <div class="w-fit my-2 flex flex-col justify-center items-center">
        <h1 class="py-2 font-bold text-3xl lg:text-4xl xl:text-5xl">
          Logo Proposals for {{ businessName }}
        </h1>
      </div>
      <div class="p-2.5 grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-5">
        <div
          v-for="(image, index) in filteredImages"
          class="m-2.5 border border-solid border-neutral-300 rounded-md cursor-pointer bg-white shadow-sm"
          :key="index"
          @click="selectImage(image)"
        >
          <img class="w-full h-auto" :src="image.src" :alt="image.industry" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      businessName: "",
      industry: "",
      style: "",
      tone: "",
      images: [],
      selectedImage: null,
    };
  },
  created() {
    this.businessName = this.$store.state.businessName;
    this.industry = this.$store.state.industry;
    this.style = this.$store.state.style;
    this.tone = this.$store.state.tone;
    this.images = this.$store.state.images;
  },
  computed: {
    ...mapGetters(["filteredImages"]),
  },
  methods: {
    selectImage(image) {
      this.selectedImage = image.src;
      this.$store.commit("setSelectedImage", this.selectedImage);
      this.$router.push("/modify");
    },
  },
};
</script>
