<!-- <template>
  <div>
    <h2>Logo Result:</h2>
    {{ businessName }}
    {{ selectedFont }}
    {{ selectedIcon }}

    <component :is="selectedIcon" :style="{ width: '4rem', height: '4rem' }" />


    <button @click="downloadLogo">Download</button>
    <button @click="startOver">Start Over</button>
  </div>
</template>

<script>
export default {
  props: ["businessName","selectedFont","selectedIcon"],
  components: {
    component: {
      // use the dynamically imported SVG component as the template for this component
      template: '<icon-component />',
      data() {
        return {
          iconComponent: null
        }
      },
    }
  },
  data() {
    return {
      svgWidth: 500,
      svgHeight: 500,
    };
  },
  methods: {
    startOver() {
      this.$router.push('/');
    },
    components: {
    iconComponent: null
  },
    async created() {
    // load the icon component and set it as the component for this instance
    const iconComponent = await this.loadIconComponent()
    this.$options.components.iconComponent = iconComponent
  }
  },
};
</script> -->

<template>
  <div>
    <div class="canvas-container m-2.5 rounded-md cursor-pointer"
          @click="selectImage(image)">
      <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
    </div>
    <div class="canvas-container m-2.5 border border-solid border-neutral-300 rounded-md cursor-pointer bg-neutral-200"
          @click="selectImage(image)">
      <canvas ref="canvas2" :width="canvasWidth" :height="canvasHeight"></canvas>
    </div>
    <div class="canvas-container m-2.5 border border-solid border-neutral-300 rounded-md cursor-pointer"
          @click="selectImage(image)">
      <canvas ref="canvas3" :width="canvasWidth" :height="canvasHeight"></canvas>
    </div>
    <div class="canvas-container m-2.5 border border-solid border-neutral-300 rounded-md cursor-pointer bg-neutral-200"
          @click="selectImage(image)">
      <canvas ref="canvas4" :width="canvasWidth" :height="canvasHeight"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      canvasWidth: 350,
      canvasHeight: 300,
      businessName: "",
      colorPalette: ['#FF2D3D', '#00AEEF', '#FFD700', '#00FF00', '#800080'],  // testy palety kolorów
      fontPalette: ["Bungee", "Oswald", "Satisfy", "Amatic SC"]
    };
  },
  created() {
    this.businessName = this.$store.state.businessName;
    this.selectedImage = this.$store.state.selectedImage;
  },
  mounted() {
    this.previewLogo();
  },
  watch: {
    // aktualizuj kolor tekstu i ikony w podglądzie preview Logo
    iconColor: function() {
      this.drawCanvas();
    }
  },
  methods: {
    previewLogo() {
      const index1 = 0;
      this.drawCanvas(this.$refs.canvas, this.colorPalette[index1], this.colorPalette[index1], 0, '#FF2D3D');
      const index2 = 1;
      this.drawCanvas(this.$refs.canvas2, this.colorPalette[index2], this.colorPalette[index2], 3);
      const index3 = 2;
      this.drawCanvas(this.$refs.canvas3, this.colorPalette[index3], this.colorPalette[index3], 2);
      const index4 = 3;
      this.drawCanvas(this.$refs.canvas4, this.colorPalette[index4]);
    },
    drawCanvas(canvasRef, iconColor, textColor, fontIndex, borderColor) {
      const canvas = canvasRef;
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.onload = () => {
        const imgRatio = img.height / img.width;
        const imgHeight = this.canvasWidth * imgRatio;
        const scaledImgHeight = imgHeight / 1.7;
        

        let fontSize = 50;
        ctx.font = `${fontSize}px ${this.fontPalette[fontIndex]}`; // Ustaw odpowiedni font z tablicy
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        const textMargin = 45;
        const textWidth = ctx.measureText(this.businessName).width;
        if (textWidth > this.canvasWidth) {
          fontSize = fontSize * (this.canvasWidth / textWidth);
          ctx.font = `${fontSize}px ${this.fontPalette[fontIndex]}`;
        }
        // Obliczenia dla wypośrodkowania obrazu i tekstu
        const totalHeight = scaledImgHeight + fontSize + textMargin * .5; // Całkowita wysokość obrazu i tekstu z dwoma marginesami
        const offsetY = (this.canvasHeight - totalHeight) / 2; // Wysokość, aby wypośrodkować obraz i tekst
        const textY = offsetY + scaledImgHeight + textMargin; // Y dla tekstu, aby był pod obrazem z marginesem

        let icon = this.colorizeImage(img, iconColor);

        if (borderColor) {
        // Dodaj obramowanie
          ctx.strokeStyle = borderColor;
          ctx.lineWidth = 20;

          // Ustal współrzędne i rozmiary prostokąta tak, aby obejmował obraz i tekst
          const rectX = 0;
          const rectY = 0;
          const rectWidth = this.canvasWidth;
          const rectHeight = this.canvasHeight;

          ctx.strokeRect(rectX, rectY, rectWidth, rectHeight);
        }
        ctx.drawImage(icon, 0, offsetY, this.canvasWidth, scaledImgHeight);
        ctx.fillStyle = textColor; // Ustaw kolor tekstu na przekazany kolor
        ctx.fillText(this.businessName, this.canvasWidth / 2, textY);
      };

      img.src = this.selectedImage;
    },
    colorizeImage(img, color) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'source-in';
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      return canvas;
    },
  },
};

/* paleta gradientów 
<div colors = :style="colorStyle(color)">
    gradientPalette: ["linear-gradient(to top, lime, cyan)","linear-gradient(to top, cyan, blue)",],   
    colorStyle(color) {
      // Sprawdź czy kolor to gradient, jeśli tak, ustaw background-image, w przeciwnym razie ustaw background-color
      return color.startsWith('linear-gradient') ? { backgroundImage: color } : { backgroundColor: color };
    }, */ 

/* metody z poprzedniej wersji:
 getFontSize(textLength) {
      const minLength = 1;
      const maxLength = 15;
      const minFontSize = 68;
      const maxFontSize = 28;
      // Oblicz proporcję w zakresie od 0 do 1
      const ratio = (textLength - minLength) / (maxLength - minLength);
      // Interpoluj wielkość czcionki w zakresie od minFontSize do maxFontSize
      const fontSize = minFontSize + ratio * (maxFontSize - minFontSize);
      // Zaokrąglij wielkość czcionki do liczby całkowitej
      return Math.round(fontSize);
    },

    increaseFontSize() {
      this.fontSize += 2; // Zwiększ rozmiar fontu o 2 punkty (możesz dostosować)
      this.svgFontSize = `${this.fontSize}px`;
      this.renderSvg(); // Ponownie renderuj SVG po zmianie rozmiaru fontu
    },
    decreaseFontSize() {
      this.fontSize -= 2; // Zmniejsz rozmiar fontu o 2 punkty (możesz dostosować)
      this.svgFontSize = `${this.fontSize}px`;
      this.renderSvg(); // Ponownie renderuj SVG po zmianie rozmiaru fontu
    },
    previewLogo() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");

      // załaduj obraz
      const img = new Image();
      img.onload = () => {
        // oblicz wysokość obrazka w stosunku do szerokości canvas
        const imgRatio = img.height / img.width;
        const imgHeight = this.canvasWidth * imgRatio;

        // ustaw czcionkę i styl dla tekstu
        let fontSize = 70;
        ctx.font = `${fontSize}px ${this.selectedFont}`;
        ctx.fillStyle = this.textColor;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        // oblicz odstęp między obrazkiem a tekstem
        const textMargin = 50;

        // oblicz proporcjonalną wielkość czcionki, aby tekst zmieścił się w canvas
        const textWidth = ctx.measureText(this.businessName).width;
        if (textWidth > this.canvasWidth) {
          fontSize = fontSize * (this.canvasWidth / textWidth);
          ctx.font = `${fontSize}px ${this.selectedFont}`;
        }

        // narysuj obraz i tekst na canvas
        ctx.drawImage(img, 0, 0, this.canvasWidth, imgHeight);
        let icon = this.colorizeImage(img, this.iconColor);
        ctx.drawImage(icon, 0, 0, this.canvasWidth, imgHeight);
        ctx.fillText(
          this.businessName,
          this.canvasWidth / 2,
          imgHeight + textMargin
        );
      };

      img.src = this.selectedIcon;
    },
    downloadLogo() {
      const zip = new JSZip();
      const canvas = this.$refs.canvas;

      // pobierz URL z obrazu PNG i dodaj plik PNG do archiwum zip
      const pngUrl = canvas.toDataURL("image/png");
      zip.file("logo.png", pngUrl.split(",")[1], { base64: true });

      const svgContent = `<svg>
                          <image id="myImage" xlink:href="${this.selectedImageObject.src}" width="100" height="100" />
                          <text id="myText" x="10%" y="80%" font-family="${this.selectedFont}">${this.businessName}</text>
                        </svg>`;
      zip.file("image.svg", svgContent);
      // pobierz URL z obrazu JPG i dodaj plik JPG do archiwum zip
      const jpgUrl = canvas.toDataURL("image/jpg"); // funkcja zwracająca URL z obrazu JPG
      zip.file("logo.jpg", jpgUrl.split(",")[1], { base64: true });

      // stwórz katalog web i dodaj do niego plik favicon.ico
      const webFolder = zip.folder("web");
      const faviconUrl = canvas.toDataURL("image/x-icon");
      webFolder.file("favicon.ico", faviconUrl.split(",")[1], { base64: true });

      // generuj paczkę zip
      zip.generateAsync({ type: "blob" }).then(function (content) {
        // pobierz paczkę zip
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = "logo.zip";
        link.click();
      });
    },
*/

</script>

<style scoped>
.canvas-container {
  display: inline-block;
  margin-right: 20px;
}

.bordered-canvas {
  border: 2px solid black;
}
</style>
