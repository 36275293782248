<template>
  <div>
            <!-- BETA INFO -->
            <div class="w-full sm:text-base sticky top-0 bg-red-500 flex justify-center items-center text-white font-bold z-10">
      Important: As we are in the Beta Phase we recommend using Google Chrome, Mozilla Firefox, Opera, Microsoft Edge.
    </div>
    <div
      v-if="step === 1"
      class="h-screen flex flex-col justify-between items-center bg-neutralGray "
    >
      <!-- LOGO BAR -->
      <div
        class="w-full h-[50px] bg-white flex justify-between items-center text-black"
      >
      <router-link class="ml-[5%]" to="/">
      <p class="font-inter font-bold text-2xl">
        Logo<span class="text-secondary">Ya</span>
      </p>
      </router-link>
        <h2 class="w-fit font-inter text-base text-primary">{{ step }}/4</h2>
        <!-- THIS IS NOT VISIBLE, ONLY FOR CENTERING PURPOSES -->
        <p class="mr-[5%] font-inter font-bold text-2xl invisible">
          Logo<span class="text-secondary">Ya</span>
        </p>
      </div>

      <!-- MAIN CARD 1 -->
      <div class="animate-fade-up animate-ease-in-out h-4/5 max-w-[90%] lg:max-w-[80%] w-full my-0 mx-auto text-primary font-inter">
  <div class="h-full w-full max-w-[90%] lg:max-w-[80%] my-0 mx-auto px-3 pt-2 pb-6 flex flex-col justify-start items-center rounded-md">
    <div class="w-full">
      <h1 class="py-2 font-bold text-3xl lg:text-4xl xl:text-5xl">
        What is the name of your business?
      </h1>
      <h2 class="mt-4 text-xl lg:text-2xl">
        We'd love to know your unique business name.
      </h2>
    </div>
    <div class="w-full mt-16 flex justify-center items-center">
      <div class="w-11/12 md:w-2/5 flex flex-col justify-center items-center">
        <input
          type="text"
          placeholder="Enter your business name"
          class="rounded-md border-2 font-bold w-full border-neutral-300 py-2 px-4 text-primary"
          v-model="businessName"
          maxlength="15"
        />
        <p class="p-1 mt-2 self-start text-xs lg:text-sm text-black">
          Business name should not be longer than 15 characters
        </p>
      </div>
    </div>
    <!-- Button Section -->
    <div class="w-full mt-8 pb-8 flex justify-center items-center">
      <button
        class="bg-secondary text-primary font-bold font-inter disabled:bg-neutral-300 border-none rounded-md py-3 px-6"
        @click="nextStep()"
            :disabled="!businessName"
      >
        Next →
      </button>
    </div>
  </div>
</div>
    </div>

    <div
      v-else-if="step === 2"
      class="h-screen flex flex-col justify-between items-center bg-neutralGray "
    >
      <!-- LOGO BAR -->
      <div
        class="w-full h-[50px] bg-white flex justify-between items-center text-black"
      >
      <router-link class="ml-[5%]" to="/">
      <p class="font-inter font-bold text-2xl">
        Logo<span class="text-secondary">Ya</span>
      </p>
      </router-link>
        <h2 class="w-fit font-inter text-base text-primary">{{ step }}/4</h2>
        <!-- THIS IS NOT VISIBLE, ONLY FOR CENTERING PURPOSES -->
        <p class="mr-[5%] font-inter font-bold text-2xl invisible">
          Logo<span class="text-secondary">Ya</span>
        </p>
      </div>

      <!-- MAIN CARD 2 -->
      <div
        class="h-4/5 max-w-[90%] lg:max-w-[80%] w-full my-0 mx-auto text-primary font-inter"
      >
        <div
          class="h-full w-full max-w-[90%] lg:max-w-[80%] my-0 mx-auto px-3 pt-2 pb-6 flex flex-col justify-start items-center rounded-md"
        >
          <div class="pb-4 w-full">
            <h1 class="py-2 font-bold text-3xl lg:text-4xl xl:text-5xl">
              What industry are you in?
            </h1>
            <h2 class="mt-4 text-xl lg:text-2xl">
              Help us understand your specific target market or customer
              segment.
            </h2>
          </div>
          <div
            class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 auto-rows-fr"
          >
            <button
              class="btn"
              @click="selectIndustry('BeautyFashion')"
              :class="{ selected: industry === 'BeautyFashion' }"
            >
            <img class="w-full h-10" src="./@/../../../public/icons/BeautyFashion/BF9.svg" alt="Standard Icon" />
              Beauty & Fashion
            </button>
            <button
              class="btn"
              @click="selectIndustry('FoodRestaurant')"
              :class="{ selected: industry === 'FoodRestaurant' }"
            >
            <img class="w-full h-10" src="./@/../../../public/icons/FoodRestaurant/FR19.svg" alt="Standard Icon" />
              Food & Restaurant
            </button>
            <button
              class="btn"
              @click="selectIndustry('HealthFitness')"
              :class="{ selected: industry === 'HealthFitness' }"
            >
            <img class="w-full h-10" src="./@/../../../public/icons/HealthFitness/HF30.svg" alt="Standard Icon" />
              Health & Fitness
            </button>
            <button
              class="btn"
              @click="selectIndustry('MarketingSales')"
              :class="{ selected: industry === 'MarketingSales' }"
            >
            <img class="w-full h-10" src="./@/../../../public/icons/MarketingSales/MS26.svg" alt="Standard Icon" />
              Marketing & Sales
            </button>
            <button
              class="btn"
              @click="selectIndustry('RealEstateConstruction')"
              :class="{ selected: industry === 'RealEstateConstruction' }"
            >
            <img class="w-full h-10" src="./@/../../../public/icons/RealEstateConstruction/RC19.svg" alt="Standard Icon" />
              Real Estate & Construction
            </button>
            <button
              class="btn"
              @click="selectIndustry('FinanceLegal')"
              :class="{ selected: industry === 'FinanceLegal' }"
            >
            <img class="w-full h-10" src="./@/../../../public/icons/FinanceLegal/FL2.svg" alt="Standard Icon" />
              Finance & Legal
            </button>
            <button
              class="btn"
              @click="selectIndustry('Other')"
              :class="{ selected: industry === 'Other' }"
            >
              Other
            </button>
          </div>
          <div class="w-full mt-8 pb-8 flex justify-center items-center">
              <!-- Previous Button -->
              <button
                class="text-primary font-inter disabled:bg-neutral-300 border-none py-3 px-6 mr-4"
                @click="previousStep()"
            :disabled="!businessName"
              >
                ← Previous
              </button>

              <!-- Next Button -->
              <button
                class="bg-secondary text-primary font-bold font-inter disabled:bg-neutral-300 border-none rounded-md py-3 px-6"
                @click="nextStep()"
                :disabled="!industry"
              >
                Next →
              </button>
            </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="step === 3"
      class="h-screen flex flex-col justify-between items-center bg-secondGray"
    >
      <!-- LOGO BAR -->
      <div
        class="w-full h-[50px] bg-white flex justify-between items-center text-black"
      >
      <router-link class="ml-[5%]" to="/">
      <p class="font-inter font-bold text-2xl">
        Logo<span class="text-secondary">Ya</span>
      </p>
      </router-link>
        <h2 class="w-fit font-inter text-base text-primary">{{ step }}/4</h2>
        <!-- THIS IS NOT VISIBLE, ONLY FOR CENTERING PURPOSES -->
        <p class="mr-[5%] font-inter font-bold text-2xl invisible">
          Logo<span class="text-secondary">Ya</span>
        </p>
      </div>

      <!-- MAIN CARD 3 -->
      <div
        class="h-4/5 max-w-[90%] lg:max-w-[80%] w-full my-0 mx-auto text-primary font-inter"
      >
        <div
          class="h-full w-full max-w-[90%] lg:max-w-[80%] my-0 mx-auto px-3 pt-2 pb-6 flex flex-col justify-start items-center rounded-md"
        >
          <div class="pb-4 w-full">
            <h1 class="py-2 font-bold text-3xl lg:text-4xl xl:text-5xl">
              What logo style would you prefer?
            </h1>
            <h2 class="mt-4 text-xl lg:text-2xl">
              What colors would you prefer?
            </h2>
          </div>
          <div
            class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 auto-rows-fr"
          >
            <button
              class="btn relative"
              @click="selectStyle('standardColors')"
              :class="{ selected: style === 'standardColors' }"
            >
              Standard
                <span class="inline-block">
                  <!-- Use an img tag to display the SVG icon -->
                  <img class="w-25 h-25 text-white" src="@/assets/standard.svg" alt="Standard Icon" />
                </span>
            </button>
            <button
              class="btn"
              @click="selectStyle('lightColors')"
              :class="{ selected: style === 'lightColors' }"
            >
              Light
              <span class="inline-block">
                  <!-- Use an img tag to display the SVG icon -->
                  <img class="w-25 h-25 text-white" src="@/assets/light.svg" alt="Standard Icon" />
                </span>
            </button>
            <button
              class="btn"
              @click="selectStyle('darkColors')"
              :class="{ selected: style === 'darkColors' }"
            >
              Dark
              <span class="inline-block">
                  <!-- Use an img tag to display the SVG icon -->
                  <img class="w-25 h-25 text-white" src="@/assets/dark.svg" alt="Standard Icon" />
                </span>
            </button>           
            <button
              class="btn"
              @click="selectStyle('warmColors')"
              :class="{ selected: style === 'warmColors' }"
            >
              Warm
              <span class="inline-block">
                  <!-- Use an img tag to display the SVG icon -->
                  <img class="w-25 h-25 text-white" src="@/assets/warm.svg" alt="Standard Icon" />
                </span>
            </button>
            <button
              class="btn"
              @click="selectStyle('coldColors')"
              :class="{ selected: style === 'coldColors' }"
            >
              Cold
              <span class="inline-block">
                  <!-- Use an img tag to display the SVG icon -->
                  <img class="w-25 h-25 text-white" src="@/assets/cold.svg" alt="Standard Icon" />
                </span>
            </button>
            <button
              class="btn"
              @click="selectStyle('vintageColors')"
              :class="{ selected: style === 'vintageColors' }"
            >
              Vintage
              <span class="inline-block">
                  <!-- Use an img tag to display the SVG icon -->
                  <img class="w-25 h-25 text-white" src="@/assets/vintage.svg" alt="Standard Icon" />
                </span>
            </button>
            <button
              class="btn"
              @click="selectStyle('neonColors')"
              :class="{ selected: style === 'neonColors' }"
            >
              Neon
              <span class="inline-block">
                  <!-- Use an img tag to display the SVG icon -->
                  <img class="w-25 h-25 text-white" src="@/assets/neon.svg" alt="Standard Icon" />
                </span>
            </button>
          </div>
          <div class="w-full mt-8 pb-8 flex justify-center items-center">
              <!-- Previous Button -->
              <button
                class="text-primary font-inter disabled:bg-neutral-300 border-none py-3 px-6 mr-4"
                @click="previousStep()"
            :disabled="!businessName"
              >
                ← Previous
              </button>

              <!-- Next Button -->
              <button
                class="bg-secondary text-primary font-bold font-inter disabled:bg-neutral-300 border-none rounded-md py-3 px-6"
                @click="nextStep()"
            :disabled="!style"
              >
                Next →
              </button>
            </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="step === 4"
      class="h-screen flex flex-col justify-between items-center bg-thirdGray"
    >
      <!-- LOGO BAR -->
      <div
        class="w-full h-[50px] bg-white flex justify-between items-center text-black"
      >
      <router-link class="ml-[5%]" to="/">
      <p class="font-inter font-bold text-2xl">
        Logo<span class="text-secondary">Ya</span>
      </p>
      </router-link>
        <h2 class="w-fit font-inter text-base text-primary">{{ step }}/4</h2>
        <!-- THIS IS NOT VISIBLE, ONLY FOR CENTERING PURPOSES -->
        <p class="mr-[5%] font-inter font-bold text-2xl invisible">
          Logo<span class="text-secondary">Ya</span>
        </p>
      </div>

      <!-- MAIN CARD 4 -->
      <div
        class="h-4/5 max-w-[90%] lg:max-w-[80%] w-full my-0 mx-auto text-primary font-inter"
      >
        <div
          class="h-full w-full max-w-[90%] lg:max-w-[80%] my-0 mx-auto px-3 pt-2 pb-6 xl:overflow-clip flex flex-col justify-start items-center rounded-md"
        >
          <div class="pb-4 w-full">
            <h1 class="py-2 font-bold text-3xl lg:text-4xl xl:text-5xl">
              How would you like your logo to make people feel?
            </h1>
          </div>
          <div
            class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 auto-rows-fr"
          >
            <button
              class="btn"
              @click="selectTone('Joyful')"
              :class="{ selected: tone === 'Joyful' }"
            >
            <span class="font-atma text-lg">Joyful</span><br/><span class="text-left font-light">happiness, positivity, delight</span>
            </button>
            <button
              class="btn"
              @click="selectTone('Professional')"
              :class="{ selected: tone === 'Professional' }"
            >
              <span class="font-diphylleia text-lg">Professional</span><br/><span class="text-left font-light">competence, expertise, quality</span>
            </button>
            <button
              class="btn"
              @click="selectTone('Bold')"
              :class="{ selected: tone === 'Bold' }"
            >
            <span class="font-bungee text-lg">Bold</span><br/><span class="text-left font-light">confidence, power, asseriveness</span>
            </button>
            <button
              class="btn"
              @click="selectTone('Creative')"
              :class="{ selected: tone === 'Creative' }"
            >
            <span class="font-likeSnow text-lg">Creative</span><br/><span class="text-left font-light">imagination, innovation, uniqueness</span>
            </button>
            <button
              class="btn"
              @click="selectTone('Trustworthy')"
              :class="{ selected: tone === 'Trustworthy' }"
            >
            <span class="font-dosis text-lg">Trustworthy</span><br/><span class="text-left font-light">reliability, credibility, integrity</span>
            </button>
          </div>
          <div class="w-full mt-8 pb-8 flex justify-center items-center">
              <!-- Previous Button -->
              <button
                class="text-primary font-inter disabled:bg-neutral-300 border-none py-3 px-6 mr-4"
                @click="previousStep()"
            :disabled="!businessName"
              >
                ← Previous
              </button>

              <!-- Next Button -->
              <button
                class="bg-secondary text-primary font-bold font-inter disabled:bg-neutral-300 border-none rounded-md py-3 px-6"
                @click="nextStep()"
            :disabled="!tone"
              >
                Next →
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      businessName: "",
      industry: "",
      style: "",
      tone: "",
      step: 1,
    };
  },
  methods: {
    nextStep() {
      switch (this.step) {
        case 1:
          this.$store.commit("setBusinessName", this.businessName);
          this.step++;
          break;
        case 2:
          this.$store.commit("setIndustry", this.industry);
          this.step++;
          break;
        case 3:
          this.$store.commit("setStyle", this.style);
          this.step++;
          break;
        case 4:
          this.$store.commit("setTone", this.tone);
          this.$router.push("/proposals");    // testCanva do testów 
          break;
      }
    },
    previousStep() {
      this.step--;
    },
    selectIndustry(industry) {
      this.industry = industry;
    },
    selectStyle(style) {
      this.style = style;
    },
    selectTone(tone) {
      this.tone = tone;
    },
  },
};
</script>
<style scoped>
.btn {
  @apply m-3 py-4 px-8 text-center bg-white text-gray-400 font-bold font-inter rounded-md shadow; /* Default style for buttons */
}
.bg-standard-palette:hover {
  background-image: linear-gradient(90deg, hsla(0, 67%, 44%, 1) 0%, hsla(291, 64%, 42%, 1) 29%, hsla(227, 81%, 46%, 1) 50%, hsla(120, 100%, 25%, 1) 79%, hsla(50, 91%, 52%, 1) 90%);
}
.bg-light-palette:hover {
  background-image: linear-gradient(90deg, hsla(320, 82%, 89%, 1) 0%, hsla(221, 100%, 96%, 1) 44%, hsla(176, 57%, 89%, 1) 73%, hsla(89, 84%, 95%, 1) 100%);
}
.bg-dark-palette:hover {
  background-image: linear-gradient(90deg, hsla(283, 69%, 14%, 1) 0%, hsla(320, 100%, 15%, 1) 0%, hsla(212, 75%, 16%, 1) 64%, hsla(184, 94%, 12%, 1) 99%);
}
.bg-warm-palette:hover {
  background-image: linear-gradient(90deg, hsla(346, 100%, 42%, 1) 0%, hsla(23, 100%, 50%, 1) 50%, hsla(50, 91%, 52%, 1) 100%);
}
.bg-cold-palette:hover {
  background-image: linear-gradient(90deg, hsla(200, 100%, 50%, 1) 0%, hsla(188, 96%, 44%, 1) 41%, hsla(186, 100%, 83%, 1) 78%, hsla(201, 89%, 93%, 1) 99%);
}
.bg-vintage-palette:hover {
  background-image: linear-gradient(90deg, hsla(32, 12%, 42%, 1) 0%, hsla(36, 81%, 71%, 1) 51%, hsla(54, 21%, 71%, 1) 78%, hsla(65, 91%, 92%, 1) 100%);
}
.bg-neon-palette:hover {
  background-image: linear-gradient(90deg, hsla(295, 100%, 50%, 1) 0%, hsla(180, 100%, 72%, 1) 35%, hsla(163, 100%, 64%, 1) 68%, hsla(65, 100%, 63%, 1) 96%);
}
.bg-mono-palette:hover {
  background-image: linear-gradient(to right, #000000, #343A40, #6C757D, #FFFFFF)
}
.selected {
  @apply bg-secondary text-primary; /* Add a style for selected buttons */
}
.font-atma {
  font-family: 'Atma', sans-serif; 
}
.font-diphylleia {
  font-family: 'Diphylleia', sans-serif;
}
.font-bungee {
  font-family: 'Bungee', sans-serif;
}
.font-likeSnow {
  font-family: 'Like Snow', sans-serif;
}
.font-dosis {
  font-family: 'Dosis', sans-serif;
}
</style>
