<template>
    <div
     class="w-screen h-screen flex flex-col justify-between items-center font-inter"
   >
     <!-- LOGO BAR -->
     <div
       class="w-full h-[50px] bg-white flex justify-start items-center text-black"
     >
       <p class="ml-[5%] font-inter font-bold text-2xl">
         Logo<span class="text-secondary">Ya</span>
       </p>
     </div>
 
     <!--MAIN CONTENT-->
     <div class="h-full w-full flex flex-col xl:flex-row">
       <!-- LEFT SECTION -->
       <div
         class="w-full h-1/3 xl:w-1/2 xl:h-full bg-secondary text-white flex flex-col justify-evenly items-center text-center xl:text-left"
       >
         <div class="w-full flex flex-col justify-center items-center">
           <h1 class="w-3/4 pb-10 font-bold text-3xl lg:text-4xl xl:text-5xl">
             Sign in!
           </h1>
           <p class="w-3/4">Sign in!</p>
         </div>
 
         <!-- USED ONLY TO MOVE TITLE ON DESKTOP -->
         <div class="hidden xl:block"></div>
       </div>
 
       <!-- RIGHT SECTION -->
       <div
         class="w-full h-2/3 xl:w-1/2 xl:h-full bg-white flex flex-col justify-center items-center"
       >
         <form
           class="h-full w-3/4 xl:w-2/3 flex flex-col justify-center items-center xl:items-start text-primary"
         >
           <label class="w-full md:w-2/3 xl:w-4/5 mt-4 flex">
             <img src="../assets/icon_message.svg" alt="message" class="p-4" />
             <input
               type="text"
               class="w-full h-14 p-4 border border-gray-300 placeholder:text-gray-500 rounded"
               placeholder="Email"
               v-model='email'
               required
             />
           </label>
           <label class="w-full md:w-2/3 xl:w-4/5 mt-4 flex">
             <img src="../assets/icon_message.svg" alt="message" class="p-4" />
             <input
               type="password"
               class="w-full h-14 p-4 border border-gray-300 placeholder:text-gray-500 rounded"
               placeholder="Password"
               v-model='password'
               required
             />
           </label>
           <div class="flex w-full md:w-fit mt-8">
             <img
               src="../assets/icon_message.svg"
               alt="message"
               class="invisible hidden xl:block p-4"
             />
           </div>
         </form>
         <p v-if="errMsg">{{ errMsg }}</p>
         <p><button @click="register">Submit</button></p>
         <p><button @click="signInWithGoogle" >Sign In With Google</button></p>
       </div>
     </div>
   </div>
 </template>
   
   <script setup>
   import { ref } from "vue";
   import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
   const email = ref("");
   const password = ref("");
   const errMsg = ref()
   const register = () => {
       signInWithEmailAndPassword(getAuth(), email.value, password.value)
       .then((data) => {
           console.log("Successfully Registered!");
           console.log(auth.currentUser)
       })
       .catch((error) => { 
       console.log(error.code);
       switch (error.code) {
         case "auth/invalid-email":
           errMsg.value = "Invalid email";
           break;
         case "auth/user-not-found":
           errMsg.value = "No account with that email was found";
           break;
         case "auth/wrong-password":
           errMsg.value = "Incorrect password";
           break;
         default:
           errMsg.value = "Email or password was incorrect";
           break;
       }
       alert(error.message);
       });
   };
   
   const signInWithGoogle = () => {
     const provider = new GoogleAuthProvider();
     signInWithPopup(getAuth(), provider)
     .then((result) => {
       console.log(result.user);
     })
     .catch((error) => { 
       console.log(error.code);
       alert(error.message);
     });
   };
   
   </script>