<template>
   <div
    class="w-screen h-screen flex flex-col justify-between items-center font-inter"
  >
    <!-- LOGO BAR -->
    <div
      class="w-full h-[50px] bg-white flex justify-start items-center text-black"
    >
      <p class="ml-[5%] font-inter font-bold text-2xl">
        Logo<span class="text-secondary">Ya</span>
      </p>
    </div>

    <!--MAIN CONTENT-->
    <div class="h-full w-full flex flex-col xl:flex-row">
      <!-- LEFT SECTION -->
      <div
        class="w-full h-1/3 xl:w-1/2 xl:h-full bg-secondary text-white flex flex-col justify-evenly items-center text-center xl:text-left"
      >
        <div class="w-full flex flex-col justify-center items-center">
          <h1 class="w-3/4 pb-10 font-bold text-3xl lg:text-4xl xl:text-5xl">
            Sign up today and unleash your brand
          </h1>
          <p class="w-3/4">Get ahead of your competition.</p>
        </div>

        <!-- USED ONLY TO MOVE TITLE ON DESKTOP -->
        <div class="hidden xl:block"></div>
      </div>

      <!-- RIGHT SECTION -->
      <div
        class="w-full h-2/3 xl:w-1/2 xl:h-full bg-white flex flex-col justify-center items-center"
      >
        <form
          class="h-full w-3/4 xl:w-2/3 flex flex-col justify-center items-center xl:items-start text-primary"
        >
          <label class="w-full md:w-2/3 xl:w-4/5 mt-4 flex">
            <img src="../assets/icon_message.svg" alt="message" class="p-4" />
            <input
              type="text"
              class="w-full h-14 p-4 border border-gray-300 placeholder:text-gray-500 rounded"
              placeholder="Email"
              v-model='email'
              required
            />
          </label>
          <label class="w-full md:w-2/3 xl:w-4/5 mt-4 flex">
            <img src="../assets/icon_message.svg" alt="message" class="p-4" />
            <input
              type="password"
              class="w-full h-14 p-4 border border-gray-300 placeholder:text-gray-500 rounded"
              placeholder="Password"
              v-model='password'
              required
            />
          </label>
          <label class="w-full md:w-2/3 xl:w-4/5 mt-4 flex">
            <img src="../assets/icon_message.svg" alt="message" class="p-4" />
            <input
              type="text"
              class="w-full h-14 p-4 border border-gray-300 placeholder:text-gray-500 rounded"
              placeholder="Display Name"
              v-model='displayName'
              required
            />
          </label>
          <div class="flex w-full md:w-fit mt-8">
            <img
              src="../assets/icon_message.svg"
              alt="message"
              class="invisible hidden xl:block p-4"
            />
          </div>
        </form>
        <p v-if="errMsg">{{ errMsg }}</p>
        <p><button @click="register">Submit</button></p>
        <p><button @click="signInWithGoogle" >Sign In With Google</button></p>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
  import { ref } from "vue";
  import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
  import { getDatabase, ref as refFS, set } from "firebase/database";
  const email = ref("");
  const password = ref("");
  const displayName = ref("");
  const errMsg = ref();
  const database = getDatabase();
  const auth = getAuth();
  const register = () => {
    createUserWithEmailAndPassword(getAuth(), email.value, password.value)
      .then((userCredential) => {
        const user = userCredential.user;
        set(refFS(database, 'users/' + user.uid),{
          username: displayName,
          email: email
        });
          console.log("Successfully Registered!");
          console.log(auth.currentUser);
      })
      .catch ((error) => {
          console.log(error.code);
        switch (error.code) {
          case "auth/email-already-in-use":
           errMsg.value = "Provided email address is already in use.";
           break;
          case "auth/invalid-password":
           errMsg.value = "Provided password is too simple. It must be at least within six characters, contain one upper case, one lower case, and a number.";
           break;
          case "auth/invalid-email":
           errMsg.value = "Provided email is invalid. Please check your input.";
           break;
          case "auth/invalid-display-name":
           errMsg.value = "Provided username is invalid. Please check your input.";
           break;
         default:
           errMsg.value = "Error, please try again.";
           break;
       }
          alert(error.message);
      });
  };
  
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(getAuth(), provider)
    .then((result) => {
      console.log(result.user);
    })
    .catch((error) => { 
      console.log(error.code);
      alert(error.message);
    });
  };
  
  </script>