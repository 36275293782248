<template>
    <div class="w-screen h-screen flex">
      <!-- Lewy sidebar -->
      <div class="w-[400px] h-full bg-gray-200 text-white" v-show="showSide">
        <div class="h-[50px] bg-gray-900 flex justify-start  items-center ">
          <div class="px-[20px]">
            <router-link class="ml-[5%]" to="/">
              <p class="font-inter font-bold text-2xl">
              Logo<span class="text-secondary">Ya</span>
              </p>
            </router-link>
          </div>
        </div>
        <div class="h-[calc(100vh-50px)] bg-primary py-[20px]">
          <div class="flex flex-col justify-between h-full px-[20px] space-y-[10px]">
            <div class=" flex flex-col justify-between space-y-[10px]">
              <router-link to="/dashboardPage" class="inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium rounded-md border-gray-200 hover:bg-secondary hover:text-gray-800  transition duration-400 ease-in-out">
                <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path>
                </svg>
                Option 1
              </router-link>
              <router-link to="/dashboardPage2" class="inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium rounded-md border-gray-200 hover:bg-secondary  hover:text-gray-800 transition duration-400 ease-in-out">
                <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path>
                </svg>
                Option 2
              </router-link>
              <router-link to="/SignUp" class="inline-flex relative items-center py-[10px] px-[10px] w-full text-sm font-medium rounded-md border-gray-200 hover:bg-secondary  hover:text-gray-800 transition duration-400 ease-in-out">
                <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z" clip-rule="evenodd"></path>
                </svg>
                Option 3
              </router-link>  
            </div>

            <!-- Log Out -->
            <div class="h-[50px]">
            <div>
              <router-link to="/" class="inline-flex relative items-center py-[10px] px-[10px] w-full transition duration-400 ease-in-out">
                <svg aria-hidden="true" class="mr-2 w-[50px] h-[50px] fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="m13.5 7.25h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z" fill="#000000" style="fill: rgb(255, 255, 255);"></path><path d="m12.03 11.03 2.5-2.5c.293-.293.293-.767 0-1.06l-2.5-2.5c-.292-.293-.768-.293-1.06 0-.293.292-.293.768 0 1.06l1.969 1.97s-1.969 1.97-1.969 1.97c-.293.292-.293.768 0 1.06.292.293.768.293 1.06 0z" ></path><path d="m8 13.25h-5c-.066 0-.13-.026-.177-.073s-.073-.111-.073-.177v-10c0-.066.026-.13.073-.177s.111-.073.177-.073h5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75c-1.537 0-3.463 0-5 0-.464 0-.909.184-1.237.513-.329.328-.513.773-.513 1.237v10c0 .464.184.909.513 1.237.328.329.773.513 1.237.513h5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z" clip-rule="evenodd"></path>
                </svg> 
              </router-link>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="w-full h-full bg-gray-400">
        <div class="h-[50px] bg-gray-100 flex items-center shadow-sm px-[20px] w-full py-[10px] z-10 border-b ">
          <!-- Hambuger menu -->
          <div class="cursor-pointer w-[30px]" @click="toggleSidebar">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class=" w-[25px] h-[25px]">
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
          </div>

          <div class="w-[calc(100%-30px)]">
            <div class="flex justify-end ">
              <!-- User -->
              <div class="w-[200px]">
                <div class="flex items-center justify-end" @click="toggleDrop">
                  <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px]" fill="#102542" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path>
                  </svg>
                  <div class="font-semibold text-primary">
                    <div>User Name</div>
                  </div>
                </div>
                <!-- User drop down -->
                <div v-show="showUserDropdown" class="absolute right-[10px] z-10 mt-2 w-56 origin-top-right rounded-md bg-primary shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-user">
                  <div class="py-1 text-left">
                    <a href="#" class="text-white block px-4 py-2 text-md hover:text-secondary" id="user-setting-0">Settings</a>
                    <a href="#" class="text-white block px-4 py-2 text-md hover:text-secondary"  id="user-setting-1">Option 2</a>
                    <a href="#" class="text-white block px-4 py-2 text-md hover:text-secondary"  id="user-setting-2">Option 3</a>
                  </div>
                </div>
              </div>

            </div>
          </div>  
        </div>

        <div class="h-[calc(100vh-50px)] bg-gray-50 p-[20px]">
          <div class="border border-gray-300 rounded-md p-[20px] h-full">
            <router-view></router-view>
          </div>
        </div>
      </div>
      <!-- Main  -->
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        showSide: true,
        showUserDropdown: false,
      }
    },
    methods: {
      // pokaż / schowaj side bar
      toggleSidebar() {
        this.showSide = !this.showSide
      },
      // pokaż / schowaj panel usera 
      toggleDrop() {
        this.showUserDropdown = !this.showUserDropdown
      }
    }
  
  }
  </script>
  
  <style>
  
  </style>