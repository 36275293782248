import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style.css'
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref as refFS, set } from 'firebase/database';

const firebaseApp = initializeApp ({
    apiKey: "AIzaSyBS-QBxo4iFTejHzZ9cOR2CThmtUAfhj1c",
    authDomain: "logoya-ad907.firebaseapp.com",
    databaseURL: "https://logoya-ad907-default-rtdb.firebaseio.com",
    projectId: "logoya-ad907",
    storageBucket: "logoya-ad907.appspot.com",
    messagingSenderId: "454557623614",
    appId: "1:454557623614:web:c8f0c3fc87d21e5f18f86f",
    measurementId: "G-HRYHQ91BHS"
});

const auth = getAuth(firebaseApp);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
